import { render, staticRenderFns } from "./ImageItem.vue?vue&type=template&id=c4bbd858&scoped=true"
import script from "./ImageItem.vue?vue&type=script&lang=js"
export * from "./ImageItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4bbd858",
  null
  
)

export default component.exports