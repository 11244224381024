<template>
  <v-row align="center" justify="center">
    <v-col class="textcenter" cols="6">
      <div class="mx-md-3 mx-sm-0 d-flex justify-end">
        <add-comment
          :add-comment-action="AddCommentAction"
          :ticketId="ticketId"
          @new-comment="addNewComment"
        ></add-comment>
      </div>
    </v-col>
    <v-col class="textcenter" cols="6">
      <div class="mx-1 d-flex justify-start align-items-center">
        <add-resolution
          v-if="getUserId"
          :can-add="AddResolutionAction"
          :ticket-id="ticketId"
          :ticket-status="ticketStatus"
          :group="group"
          :user-id="getUserId"
          @new-task="addNewTask"
          @new-solution="addNewSolution"
          @new-comment="addNewComment"
        ></add-resolution>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { ticketActionPermissions } from "../utils";
import { mapGetters } from "vuex";
import TICKET_API from "../api/ticket";
import USER_API from "../api/user";
import AddComment from "./ticket/AddComment";
import AddResolution from "./ticket/AddResolution";

export default {
  name: "TicketAction",
  props: {
    ticketId: {
      type: Number,
      required: true
    },
    entityId: {
      required: true
    },
    ticketStatus: {
      required: true
    }
  },
  components: { AddComment, AddResolution },
  data() {
    return {
      group: undefined,
      error: null
    };
  },
  computed: {
    ...mapGetters(["getUserProfiles", "getUserId"]),
    AddCommentAction() {
      let flag = false;
      for (let i = 0; i < this.getUserProfiles.length; i++) {
        if (
          ticketActionPermissions.checkPermission(1, this.getUserProfiles[i])
        ) {
          flag = true;
        }
      }
      return flag;
    },
    AddResolutionAction() {
      let flagAdd = false;
      for (let i = 0; i < this.getUserProfiles.length; i++) {
        if (
          ticketActionPermissions.checkPermission(2, this.getUserProfiles[i])
        ) {
          flagAdd = true;
        }
      }
      return flagAdd;
    }
  },
  methods: {
    addNewComment(comment) {
      this.$store.commit("addITILFollowups", comment);
      this.$emit("newComment", comment);
    },
    addNewTask(task) {
      this.$store.commit("addTicketsTask", task);
      this.$emit("newTask", task);
    },
    addNewSolution(solution) {
      this.$store.commit("addITILSolution", solution);

      this.$emit("newSolution", solution);
    },
    loadGroup() {
      if (this.entityId) {
        TICKET_API.getMyEntity(this.entityId)
          .then(({ data }) => {
            TICKET_API.getGroup(data.entities_id).then(async result => {
              if (result.data[0] !== undefined) {
                this.group = result.data[0];
              } else {
                // si le groupe est sur l'entité de niveau 4 on remonte d'un cran
                await USER_API.setActiveEntity(this.entityId);
                TICKET_API.getMyEntity(this.entityId).then(({ data }) => {
                  for (let i = 0; i < data.length; i++) {
                    if (data[i].level === 4) {
                      TICKET_API.getGroup(data[i].id).then(result => {
                        if (result.data[0] !== undefined) {
                          this.group = result.data[0];
                        } else {
                          // si le groupe est sur l'entité de niveau 3 on remonte d'un cran
                          for (let j = 0; j < data.length; j++) {
                            if (data[j].level === 3) {
                              TICKET_API.getGroup(data[j].id).then(result => {
                                this.group = result.data[0];
                              });
                            }
                          }
                        }
                      });
                    }
                  }
                });
              }
            });
          })
          .catch(error => {
            this.group = null;
            this.error = error;
          });
      }
    }
  },
  watch: {
    entityId() {
      this.loadGroup();
    }
  },
  created() {
    this.loadGroup();
  }
};
</script>

<style scoped>
.actionBtn {
  width: 80%;
  color: white;
}
</style>
