import { reactive, toRefs } from "@vue/composition-api";
//import USER_API from "../api/user";
import { getColor, loadStates, SOLUTION_TYPE } from "../utils";
export default function getITFollowUpSolutions(ticketId, itSolutions) {
  const state = reactive({
    ITILSolutionLoaded: false,
    ITILSolution: [],
    users: []
  });
  // CCA modif l'affichage du nom des rédacteurs de solutions, initialement Glpi au lieur de Pilote Immo
  const loadITFollowUpSolution = () => {
    state.ITILSolution = itSolutions.value.map(el => {
      el.actionType = SOLUTION_TYPE;
      el.color = getColor(el.status);

      if (!el.content) {
        if (el.status === 4)
          el.content = `Refusé le ${new Date(el.date_approval)} par PiTracker`;
      }
      return el;
    });

    /*state.ITILSolution.forEach(async (el, index) => {
      state.ITILSolution[index].user_name = "...";
      const userIndex = state.users.findIndex(user => user.id === el.users_id);
      if (userIndex > -1) {
        const user = state.users[userIndex];
        const userName = `${user.firstname} ${user.realname}`;
        state.ITILSolution[index].user_name = userName ? userName : "...";
      } else {
        await USER_API.getUserData(el.users_id).then(({ data }) => {
          state.users.push(data)
          const userName = `${data.firstname} ${data.realname}`;
          state.ITILSolution[index].user_name = userName ? userName : "...";
        });
      }
    });*/
    state.ITILSolutionLoaded = loadStates.SUCCESS;
  };
  return { ...toRefs(state), loadITFollowUpSolution };
}
