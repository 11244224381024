<template>
  <v-timeline
    v-if="items.length > 0"
    :dense="$vuetify.breakpoint.smAndDown"
    :class="tmClass"
  >
    <v-timeline-item left color="warning" icon class="text-right">
      <template slot="icon">
        <v-icon x-large class="white--text">mdi-information-outline</v-icon>
      </template>
      <span slot="opposite" v-if="selectedTicket">
        <v-chip>
          <v-icon color="success">access_time </v-icon>
          {{ moment(selectedTicket.date_creation).format("DD/MM/YYYY  HH:mm") }}
        </v-chip>
      </span>
      <div style="width: 100%">
        <div class="ma-2 text-justify incident pb-2" outlined x-large>
          <div class="subtitle-1">
            Déclaration d'intervention:
          </div>
          <hr />
          <div
            class="subtitle-2 pt-2 pb-2"
            v-if="selectedTicket.content"
            v-html="decodeContent(selectedTicket.content)"
          ></div>
          <hr />
        </div>
      </div>
    </v-timeline-item>
    <v-timeline-item
      v-for="(item, i) in items"
      :key="i"
      :color="item.color"
      :left="!item.actionType || item.actionType === 3"
      :right="!!item.actionType"
      icon
      fill-dot
    >
      <template v-slot:icon>
        <v-avatar>
          <span class="white--text">{{ getAvatarName(item.user_name) }}</span>
        </v-avatar>
      </template>
      <span slot="opposite">
        <v-chip small>
          <v-icon :left="!item.actionType" :color="item.color"
            >access_time</v-icon
          >
          {{ moment(item.date_creation).format("DD/MM/YYYY  HH:mm") }}
        </v-chip>
      </span>
      <v-card :color="item.color" dark shaped raised>
        <v-card-title class="subtitle-2 pa-0 pa-md-1 pl-3 ml-3">{{
          item.tech_name
            ? item.user_name + " -> " + item.tech_name
            : item.user_name
        }}</v-card-title>
        <v-card-text class="white text--primary">
          <task-item :item="item" v-if="item.actionType === 2"></task-item>
          <v-row v-else-if="item.actionType === 3">
            <image-item :item="item"></image-item>
          </v-row>
          <div
            v-else
            class="incident"
            v-html="decodeContent(item.content)"
          ></div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item right color="primary" icon>
      <template slot="icon">
        <v-icon x-large class="white--text">mdi-information-outline</v-icon>
      </template>

      <span slot="opposite" v-if="selectedTicket">
        <v-chip>
          <v-icon color="success">access_time </v-icon>
          {{ moment().format("DD/MM/YYYY HH:mm") }}
        </v-chip>
      </span>
      <v-chip class="ma-2" color="success" outlined>
        Maintenant
      </v-chip>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { Decoder } from "../mixins/decodeContent";
import { getColor, getAvatarName } from "../utils";
import moment from "../plugins/moment";
import ImageItem from "./timeline/ImageItem";
import TaskItem from "./timeline/TaskItem";
import { mapGetters } from "vuex";

export default {
  name: "TimeLine",
  components: { TaskItem, ImageItem },
  props: {
    list: {
      type: Array,
      required: true
    },
    selectedTicket: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      items: []
    };
  },
  mixins: [Decoder],
  computed: {
    ...mapGetters(["getUsers"]),
    tmClass() {
      if (this.isMobile) {
        return "timeline__wrapper ml-n6";
      } else return "timeline__wrapper";
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    getColor,
    getAvatarName,
    moment,
    loadUserName() {
      for (let index = 0; index < this.items.length; index++) {
        const el = this.items[index];
        const ticket = el;
        let userName = null;
        let techName = null;
        const user = this.getUsers.findIndex(u => {
          return u.id == el.users_id;
        });
        if (user > -1) {
          const data = this.getUsers[user];

          userName =
            data.firstname || data.realname
              ? `${data.firstname} ${data.realname}`
              : data.name;
          if (el.users_id_tech) {
            const users_tech = this.getUsers.find(
              u => u.id == el.users_id_tech
            );

            if (users_tech) {
              techName =
                users_tech.firstname || users_tech.realname
                  ? `${users_tech.firstname} ${users_tech.realname}`
                  : users_tech.name;
            }
          }
        }
        ticket.user_name = userName ? userName : "...";
        if (techName) ticket.tech_name = techName;
        this.$set(this.items, index, ticket);
      }
    }
  },
  mounted() {
    this.items = this.list.map(el => {
      el.user_name = "...";
      return el;
    });
    this.loadUserName();
  },
  watch: {
    list() {
      this.$nextTick(() => {
        this.items = this.list.map(el => {
          el.user_name = "...";
          return el;
        });
        this.loadUserName();
      });
    },
    getUsers() {
      this.loadUserName();
    }
  }
};
</script>

<style>
.timeline__wrapper .description {
  border: 1px solid #44b02a;
  border-radius: 16px;
  background-color: transparent !important;
  margin: 8px !important;
  font-size: 14px;
  border-width: thin;
  line-height: 20px;
  outline: none;
  overflow: auto;
  padding: 0 12px;
  position: relative;
  text-decoration: none;
  transition-duration: 0.28s;
  transition-property: opacity, -webkit-box-shadow;
  transition-property: box-shadow, opacity;
  transition-property: box-shadow, opacity, -webkit-box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  white-space: nowrap;
  max-height: 250px;
}
</style>
